import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ExternalButtonLink } from "components/buttons"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  padding: 10px;
  margin: 0 auto;
`

const StyledPressKit = styled.section`
	padding: 50px 0;

	${breakpoint.medium`
		padding: 80px 0;
	`}

	.top {
		margin-bottom: 30px;
		text-align: center;

		h2 {
			margin-bottom: 10px;
		}
			
		p {
			line-height: 1.3em;
		}
	
		a {
			width: auto;
			color: ${colors.charcoal};
			margin-top: 20px;

			&:hover {
			  background-color: ${colors.charcoal};
			  color: ${colors.white};
			}
		}	
 	}

	.thumbnails_row {
		display: flex;
		max-width: 1200px;
		width: 100%;
		flex-wrap: wrap;
		column-gap: 5%;
		columns: 3;
		margin: 20px auto;
		justify-content: center;
		padding: 0 0 40px 0;

		.image_column { 
			padding-bottom: 30px;
			max-width: 300px;
			margin: 10px;

			p {
				font-size: 16px;
				text-align: center;
			    line-height: 1.2em;				
			}
		}

		.thumbnail_image { 
			width: 300px;
			height: 300px;
			border: 1px solid #333; 
			margin-bottom: 20px;
		} 
	}
`

const PressKit = () => {

  return (
    <StyledPressKit>
      <StyledContainer data-aos="fade-up">
        <div className="top">
          <h2>Images</h2>
				<p>Download the zip file that contains these images.</p>
				<ExternalButtonLink href="https://media.visbymedical.com/press-kit/visbymedical-press-kit-images.zip" target="_blank" rel="noopener noreferrer" backgroundcolor="primary">
				Download images &#x2193;
				</ExternalButtonLink>
        </div>

		<div className="thumbnails_row">
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/sexual-health/handson.png"
					width={280}
					alt="Visby Medical Sexual Health Test in hand"
					className="thumbnail_image"
				/>
				<p>Visby Medical Sexual Health Test</p>
			  </div>
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/sexual-health/device.png"
					width={280}
					alt="Front of Visby Medical Sexual Health Test"
					className="thumbnail_image"
				/>
				<p>Visby Medical Sexual Health Test</p>
			  </div>
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/sexual-health/device-angle.png"
					width={280}
					alt="Visby Medical Sexual Health Test at an angle"
					className="thumbnail_image"
				/>
				<p>Visby Medical Sexual Health Test</p>
			  </div>
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/respiratory-health/handson.png"
					width={280}
					alt="Visby Medical Respiratory Health Test in hand."
					className="thumbnail_image"
				/>
				<p>Visby Medical Respiratory Health Test</p>
			  </div>
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/respiratory-health/device.png"
					width={280}
					alt="Front of Visby Medical Respiratory Health Test."
					className="thumbnail_image"
				/>
				<p>Visby Medical Respiratory Health Test</p>
			  </div>
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/respiratory-health/device-angle.png"
					width={280}
					alt="Visby Medical Respiratory Health Test at an angle."
					className="thumbnail_image"
				/>
				<p>Visby Medical Respiratory Health Test</p>
			  </div>
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/thumbnail-logo.jpg"
					width={280}
					alt="Visby Medical logo in jpg format."
					className="thumbnail_image"
				/>
				<p>Visby Medical logo (JPEG)</p>
			</div>
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/thumbnail-logo.jpg"
					width={280}
					alt="Visby Medical logo in png format."
					className="thumbnail_image"
				/>
				<p>Visby Medical logo (PNG)</p>
			</div>
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/thumbnail-logo.jpg"
					width={280}
					alt="Visby Medical logo in eps format."
					className="thumbnail_image"
				/>
				<p>Visby Medical logo (EPS)</p>
			</div>
		</div>

        <div className="top">
          <h2>Fact Sheets</h2>
				<p>Download the zip file that contains these fact sheets.</p>
				<ExternalButtonLink href="https://media.visbymedical.com/press-kit/visby-medical-factsheets.zip" target="_blank" rel="noopener noreferrer" backgroundcolor="primary">
				Download Fact Sheets &#x2193;
				</ExternalButtonLink>
        </div>

		<div className="thumbnails_row">
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/sexual-health/fact-sheet-chlamydia.jpg"
					width={280}
					alt="Chlamydia Fact Sheet"
					className="thumbnail_image"
				/>
				<p>Chlamydia Fact Sheet</p>
			  </div>
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/sexual-health/fact-sheet-gonorrhea.jpg"
					width={280}
					alt="Gonorrhea Fact Sheet"
					className="thumbnail_image"
				/>
				<p>Gonorrhea Fact Sheet</p>
			  </div>
			<div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/sexual-health/fact-sheet-trichomoniasis.jpg"
					width={280}
					alt="Trichomoniasis Fact Sheet"
					className="thumbnail_image"
				/>
				<p>Trichomoniasis Fact Sheet</p>
			  </div>
			  <div className="image_column">
				<StaticImage
					src="../../../assets/images/resources/press-kit/sexual-health/fact-sheet-test.jpg"
					width={280}
					alt="Sexual Health Test Fact Sheet"
					className="thumbnail_image"
				/>
				<p>Sexual Health Test Fact Sheet</p>
			  </div>
		</div>
        
        </StyledContainer>
   </StyledPressKit>
  )
}

export default PressKit
